* {
  text-decoration: none !important;
}

.custom_bg {
  background-color: #f1f1f4 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.z-1{
  z-index: 1000;
}

.modal-dialog {
  max-width: 700px !important;
}

.obj_fit {
  object-fit: cover;
}

.custom_input {
  border: 2px solid #d8d8df;
  outline: none;
}
.navbar-toggler-icon {
  background-image: url(../src/assets/images/png/manu.png) !important;
}
.custom_admin_input {
  border: 2px solid #d8d8df;
  outline: none;
}

.modal-narrow {
  max-width: 400px;
  margin: auto;
}
.imprt_modal {
  max-width: 500px;
  margin: auto;
}

tr:hover td {
  background-color: rgba(177, 177, 194, 0.419);
}

.user_details tr:hover td {
  background-color: transparent;
}

#dropdown-basic {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fs_15 {
  font-size: 15px;
}

.active span {
  color: #1B84FF !important;
}
.active svg path {
  fill: #1B84FF !important;
}

span {
  color: black !important  ;
}

.custom_r {
  border-radius: 100% !important;
}

.obj_fit {
  object-fit: cover;
}

.review_tab{
  background-color: #E9F3FF;;
  color: #1B84FF;
  font-size: 0.85rem;
  font-weight: 700;
}
.approved_tab{
  background-color: #DFFFEA;;;
  color: #17C653;
  font-size: 0.85rem;
  font-weight: 700;
}
.fs_16{
  font-size: 13px;
  text-decoration: underline !important;
}
.table_scroll{
  height: 100% !important;
  overflow: auto !important;
}
.image_size_label{
  font-size: 10px;

}
.user_image_custom{
  max-width: 200px;
}
.fs_30{
  font-size: 25px;
}
.mt-6{
  margin-top: 6px;
}
.mt-8{
  margin-top: 7.5px;
}
.my_3{
  margin-top: 3.5px;
  margin-bottom: 4px;
}
.submit_btn{
  color: white;
  border: none;
  width: 140px;
  border-radius: 6px;
  height: 45px;
}
@media (min-width:2000px) {
  .d-xxxl-none{
    display: none !important;
  }
  .d-min-2000-flex{
    display: flex !important;
  }
}
@media (max-width:2000px) {
  .d-max-2000-none{
    display: none !important;
  }
  .d-max-2000-flex{
    display: flex !important;
  }
}
.outline-none{
  outline: none !important;
}
@media (min-width:992px) {
  .custom-img-login{
    height: 100vh !important;
  }
}
